import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IconMail from '../images/icon-mail';
import IconPhone from '../images/icon-phone';
import IconPin from '../images/icon-pin';
import IconQuestionMark from '../images/icon-questionmark';
import IconSend from '../images/icon-send';
import { formatTel } from '../util/String';
import ContactBotElement from './ContactBotElement';

const ContactBot = ({ onShowForm }) => {
  const { t: __ } = useTranslation();

  /** Contacts */
  const contacts = useSelector((state) => state.contacts);

  const handleMailTo = () => {
    window.location.href = `mailto:${contacts.contacts.site.email}`;
  };

  const handleCall = () => {
    window.location.href = `tel:${formatTel(contacts.contacts.site.tel)}`;
  };

  return (
    <div className="contactbot-content">
      <div className="contactbot-header">
        {__('contactbot.title_1')}&nbsp;👋
        <br />
        {__('contactbot.title_2')}
      </div>
      <ul>
        <ContactBotElement
          title={__('contactbot.sendmessage')}
          subtitle={__('contactbot.gotoform')}
          icon={IconSend}
          handle={onShowForm}
        />
        <ContactBotElement
          title={__('contactbot.secretary')}
          subtitle={contacts.contacts.site.tel.replace('(', '').replace(')', '')}
          icon={IconPhone}
          handle={handleCall}
          subtitleColor="#0D1C55"
        />
        <ContactBotElement
          title={__('contactbot.mailcontact')}
          subtitle={contacts.contacts.site.email}
          icon={IconMail}
          handle={handleMailTo}
          subtitleColor="#0D1C55"
        />
        <ContactBotElement
          title={__('contactbot.findus')}
          subtitle={__('contactbot.findexactplace')}
          icon={IconPin}
          linkUrl="/unipop/#location"
          target="_blank"
        />
        <ContactBotElement
          title={__('contactbot.faq')}
          subtitle={__('contactbot.anyquestions')}
          icon={IconQuestionMark}
          linkUrl="/faq"
          target="_blank"
        />
      </ul>
    </div>
  );
};

ContactBot.propTypes = {
  onClose: PropTypes.func.isRequired,
  onShowForm: PropTypes.func.isRequired,
};

export default ContactBot;
