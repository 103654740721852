import { useCallback, useEffect, useState } from 'react';

/**
 * useReCaptcha Hook
 *
 * Load the Google reCAPTCHA v3 script after detecting if it is not already present.
 *
 * @param {string} reCaptchaKey  The Google reCAPTCHA public key
 * @return {function}  The hook callback function
 */
const useReCaptcha = (reCaptchaKey) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const loadReCaptcha = useCallback(() => {
    return new Promise((resolve, reject) => {
      // If already loaded, resolve immediately
      if (window.grecaptcha) {
        setIsLoaded(true);
        resolve();
        return;
      }

      // If script is already being loaded, wait for it
      const existingScript = document.getElementById('recaptcha-script');
      if (existingScript) {
        existingScript.addEventListener('load', () => {
          setIsLoaded(true);
          resolve();
        });
        existingScript.addEventListener('error', reject);
        return;
      }

      // Load new script
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`;
      script.async = true;
      script.defer = true;

      script.addEventListener('load', () => {
        setIsLoaded(true);
        resolve();
      });
      script.addEventListener('error', reject);

      document.body.appendChild(script);
    });
  }, [reCaptchaKey]);

  // Load reCAPTCHA on mount
  useEffect(() => {
    loadReCaptcha().catch((error) => {
      console.error('Failed to load reCAPTCHA:', error);
    });

    // Cleanup on unmount
    return () => {
      const script = document.getElementById('recaptcha-script');
      if (script) {
        script.remove();
      }
    };
  }, [loadReCaptcha]);

  return { loadReCaptcha, isLoaded };
};

export { useReCaptcha };
export default useReCaptcha;
