import 'core-js'; // polyfills

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider as Redux } from 'react-redux';

import App from './App';
import './i18n';
import store from './redux/store';
import {
  updateDateFilter,
  updateDomainFilter,
  updateLevelFilter,
  updateOrganizerFilter,
  updateSubjectFilter,
} from './redux/trainingsSlice';
// import reportWebVitals from './reportWebVitals';
import { ScrollToElement } from './util/ScrollToElement';

/** Initialize Google tag manager */
process.env.REACT_APP_GTM_ID.length > 0 && TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

const urlParams = new URLSearchParams(window.location.search);
const dateFrom = urlParams.get('dateFrom');
const dateTo = urlParams.get('dateTo');
const organizerName = urlParams.get('organizerName');
const domain = urlParams.get('domain');
const subject = urlParams.get('subject');
const level = urlParams.get('level');
if (dateFrom && dateTo) {
  store.dispatch(updateDateFilter({ dateFrom, dateTo }));
}

if (organizerName) {
  const organizerNames = organizerName.split(',').map(function (name) {
    let testName = name.trim();
    testName = decodeURIComponent(testName);
    testName = testName.toLowerCase();
    return testName;
  });
  store.dispatch(updateOrganizerFilter({ organizerNames }));
}

if (domain) {
  const domains = domain.split(',').map((name) => decodeURIComponent(name.trim()));
  store.dispatch(updateDomainFilter({ domains }));
}

if (subject) {
  const subjects = subject.split(',').map((name) => decodeURIComponent(name.trim()));
  store.dispatch(updateSubjectFilter({ subjects }));
}

if (level) {
  const levels = level.split(',').map((name) => decodeURIComponent(name.trim()));
  store.dispatch(updateLevelFilter({ levels }));
}

if (dateFrom || dateTo || organizerName || domain || subject || level) {
  window.addEventListener('load', () => ScrollToElement('formations'));
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<></>}>
      <Redux store={store}>
        <App />
      </Redux>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
