import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { createSelector } from 'reselect';

/** Utils */
import { stringToDate } from '../util/Date';
import { normalizeString } from '../util/String';

export const trainingsSlice = createSlice({
  name: 'trainings',
  initialState: {
    isLoaded: false,
    list: [],
    error: '',
    currentTraining: {},
    filters: {
      categorieCodeUnipop: [],
      dateDebut: [],
      langueCoursNom: [],
      niveau: [],
      matiereNom: [],
      'organisateur.nom': [],
      'adresseCours.localite': [],
    },
    perPage: 8,
    currentPage: 1,
  },
  reducers: {
    /**
     * Save the trainings list
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object[]} action.payload  The trainings list
     */
    saveList: (state, action) => {
      if (action.payload.error === undefined) {
        state.isLoaded = true;
        state.list = action.payload;
        // state.list = action.payload.sort((a, b) => (stringToDate(a.dateDebut) > stringToDate(b.dateDebut) ? 1 : -1)); // Sort trainings by start date
      } else {
        state.error = action.payload.error;
      }
    },

    /**
     * Save the loading error
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current training
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The current training
     */
    saveCurrentTraining: (state, action) => {
      state.currentTraining = action.payload;
    },

    /**
     * Clear all the active filters, except those given in the `except` array, if provided.
     *
     * @param {Object} state  The Redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The reducer data
     * @param {string[]} action.payload.except  The filter that must not be cleared
     *
     */
    clearFilters: (state, action) => {
      Object.keys(state.filters).forEach((filterKey) => {
        if (!action.payload || !action.payload.except.includes(filterKey)) {
          state.filters[filterKey] = [];
        }
      });
    },

    /**
     * Save the given filter
     *
     * @param {Object} state  The Redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The reducer data
     * @param {string} action.payload.filterKey  The filter key
     * @param {string[]} action.payload.values  The filter values
     *
     */
    saveFilter: (state, action) => {
      state.filters[action.payload.filterKey] = action.payload.values;
      state.currentPage = 1;
    },

    /**
     * Save the number of list items to display per page
     *
     * @param {Object} state  The Redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The number of items to display per page
     */
    savePerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 1;
    },

    /**
     * Save the current page index
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {number} action.payload  The current page index
     */
    saveCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    /**
     *
     * Update the date filter
     *
     * @param {Object} state The redux state
     * @param {Object} action The reducer action
     * @param {Object} action.payload The date filter
     */
    updateDateFilter: (state, action) => {
      const { dateFrom, dateTo } = action.payload;
      state.currentPage = 1;
      state.filters.dateDebut = [dateFrom, dateTo];
    },

    /**
     * Update the organizer filter
     *
     * @param {Object} state The redux state
     * @param {Object} action The reducer action
     * @param {Object[]} action.payload.organizerNames The array of organizer names
     */
    updateOrganizerFilter: (state, action) => {
      const { organizerNames } = action.payload;
      state.currentPage = 1;
      state.filters['organisateur.nom'] = organizerNames;
    },

    /**
     * Update the domain filter
     *
     * @param {Object} state The redux state
     * @param {Object} action The reducer action
     * @param {Object[]} action.payload.domains The array of organizer names
     */
    updateDomainFilter: (state, action) => {
      const { domains } = action.payload;
      state.currentPage = 1;
      state.filters['categorieCodeUnipop'] = domains;
    },

    /**
     * Update the subject filter
     *
     * @param {Object} state The redux state
     * @param {Object} action The reducer action
     * @param {Object[]} action.payload.subjects The array of organizer names
     */
    updateSubjectFilter: (state, action) => {
      const { subjects } = action.payload;
      state.currentPage = 1;
      state.filters['matiereNom'] = subjects;
    },

    /**
     * Update the level filter
     *
     * @param {Object} state The redux state
     * @param {Object} action The reducer action
     * @param {Object[]} action.payload.levels The array of organizer names
     */
    updateLevelFilter: (state, action) => {
      const { levels } = action.payload;
      state.currentPage = 1;
      state.filters['niveau'] = levels;
    },
  },
});

export const {
  saveList,
  saveError,
  saveCurrentTraining,
  clearFilters,
  saveFilter,
  savePerPage,
  saveCurrentPage,
  updateDateFilter,
  updateOrganizerFilter,
  updateDomainFilter,
  updateSubjectFilter,
  updateLevelFilter,
} = trainingsSlice.actions;

/**
 * Filter a value from the training list, applying the Redux filters.
 * If listKey is provided, the values are not filtered for the given list key.
 *
 * @param {Object} filters  The Redux filters object
 * @param {Object} listItem  The current list value to filter
 * @param {string} listKey  The list key to use as filter
 * @return {boolean}  Whether the value passes the filters
 */
const filterValue = (filters, listItem, listKey) => {
  return Object.entries(filters)
    .map(([filterKey, filterValues]) => {
      if (filterValues.length === 0) return true;
      if (listKey && listKey === filterKey) return true;

      if (filterKey === 'dateDebut') {
        const itemDate = stringToDate(get(listItem, filterKey));
        return (
          itemDate >= stringToDate(filterValues[0]) &&
          (filterValues.length === 1 || itemDate <= stringToDate(filterValues[1]))
        );
      }

      if (filterKey === 'matiereNom' || filterKey === 'categorieCodeUnipop') {
        return filterValues.some((value) => get(listItem, filterKey).toLowerCase().includes(value.toLowerCase()));
      }

      return filterValues.includes(get(listItem, filterKey));
    })
    .every((value) => value === true);
};

/**
 * Reduce a value from the training list, returning only unique values for the given list key.
 *
 * @param {Array} previousValue  The previously reduced value
 * @param {Object} currentValue  The current list value to reduce
 * @param {string} listKey  The list key to use as filter
 * @return {string[]} The reduced values
 */
const reduceValue = (previousValue, currentValue, listKey) => {
  const val = get(currentValue, listKey);
  if (val && previousValue.indexOf(val) === -1) {
    previousValue.push(val);
  }
  return previousValue;
};

/**
 * Sort the values of the list by a given order, by date, or by text content.
 *
 * @param {object|string} a  The value to sort
 * @param {object|string} b  The value to sort
 * @param {string} listKey  The list key
 * @param {string[]} sortOrder  The desired sort order
 * @return {Object}  The sorted values
 */
const sortValues = (a, b, listKey, sortOrder) => {
  if (sortOrder) {
    return sortOrder.indexOf(a) - sortOrder.indexOf(b);
  } else if (listKey === 'dateDebut' || listKey === 'dateFin') {
    return stringToDate(a) > stringToDate(b) ? 1 : -1;
  } else {
    return a.localeCompare(b);
  }
};

/**
 * Return whether the trainings list is loaded without error
 *
 * @return {boolean}
 */
export const selectIsTrainingsListLoaded = createSelector(
  (state) => state.trainings,
  (trainings) => trainings.isLoaded && trainings.list.length > 0 && trainings.error.length === 0
);

/**
 * Return a slice the trainings list, with no filter applied.
 *
 * @param {number} start  The start index
 * @param {number} length  The slice length
 * @return {Object}  The trainings list slice
 */
export const selectSlice = createSelector(
  (state) => state.trainings,
  (_, start) => start,
  (_, __, length) => length,
  (trainings, start, length) => trainings.list.slice(start, start + length)
);

/**
 * Return a slice the trainings list, after the Redux filters have been applied.
 *
 * @param {number} start  The start index
 * @param {number} length  The slice length
 * @return {Object}  The trainings list slice
 */
export const selectFilteredSlice = createSelector(
  (state) => state.trainings,
  (_, start) => start,
  (_, __, length) => length,
  (trainings, start, length) =>
    trainings.list
      .filter((listItem) => filterValue(trainings.filters, listItem))
      .slice(
        start || (trainings.currentPage - 1) * trainings.perPage,
        (start || (trainings.currentPage - 1) * trainings.perPage) + (length || trainings.perPage)
      )
);

/**
 * Return the number of pages the trainings list, after the Redux filters have been applied.
 *
 * @return {number}  The number of pages
 */
export const selectFilteredNumberPages = createSelector(
  (state) => state.trainings,
  (trainings) => {
    const filteredCount = trainings.list.filter((listItem) => filterValue(trainings.filters, listItem)).length;
    return Math.ceil(filteredCount / trainings.perPage);
  }
);

/**
 * Return all the unique values for a given key, with no filter applied
 *
 * @param {string} listKey  The trainings list key
 * @return {string[]}  An array of unique values
 */
export const selectAllFilters = createSelector(
  (state) => state.trainings,
  (_, listKey) => listKey,
  (trainings, listKey) =>
    trainings.list
      .reduce((previousValue, currentValue) => reduceValue(previousValue, currentValue, listKey), [])
      .sort((a, b) => sortValues(a, b, listKey))
);

/**
 * Return the active filters keys
 *
 * @return {string[]}  An array of active filters keys
 */
export const selectActiveFilters = createSelector(
  (state) => state.trainings,
  (trainings) =>
    Object.entries(trainings.filters)
      .filter(([, filterValue]) => filterValue.length > 0)
      .map(([filterKey]) => filterKey)
);

/**
 * Return all the available unique values of the training list for a given key, after the Redux filters have been applied.
 *
 * @param {string} listKey  The trainings list key
 * @param {string[]} sortOrder  The desired sort order
 * @return {string[]}  An array of unique values
 */
export const selectAvailableFilters = createSelector(
  (state) => state.trainings,
  (_, listKey) => listKey,
  (_, __, sortOrder) => sortOrder,
  (_, __, ___, coursIds) => coursIds,
  (trainings, listKey, sortOrder, coursIds) => {
    const shouldShowAllOptions = ['organisateur.nom'].includes(listKey);

    // Filter the trainings list by coursIds if they are provided
    const filteredTrainings =
      coursIds && coursIds.length > 0
        ? trainings.list.filter((listItem) => {
            const normalizedTrainingId = listItem.coursId?.trim().toUpperCase();
            return coursIds.includes(normalizedTrainingId);
          })
        : trainings.list;

    // For institutions, skip additional filtering
    const fullyFilteredTrainings = shouldShowAllOptions
      ? filteredTrainings
      : filteredTrainings.filter((listItem) => filterValue(trainings.filters, listItem, listKey));

    // Reduce the list to unique values for the given listKey
    const reducedFilters = fullyFilteredTrainings
      .reduce((previousValue, currentValue) => {
        const val = get(currentValue, listKey);
        if (val && !previousValue.includes(val)) {
          previousValue.push(val);
        }
        return previousValue;
      }, [])
      .sort((a, b) => sortValues(a, b, listKey, sortOrder));
    return reducedFilters;
  }
);

/**
 * Return all the non available unique values of the training list for a given key, after the Redux filters have been applied.
 *
 * @param {string} listKey  The trainings list key
 * @param {string[]} sortOrder  The desired sort order
 * @return {string[]}  An array of unique values
 */
export const selectDisabledFilters = createSelector(
  (state) => state.trainings,
  (_, listKey) => listKey,
  (_, __, sortOrder) => sortOrder,
  (trainings, listKey, sortOrder) => {
    const availableFilters = trainings.list
      .filter((listItem) => filterValue(trainings.filters, listItem, listKey))
      .reduce((previousValue, currentValue) => reduceValue(previousValue, currentValue, listKey), []);

    return trainings.list
      .reduce((previousValue, currentValue) => reduceValue(previousValue, currentValue, listKey), [])
      .filter((value) => !availableFilters.includes(value))
      .sort((a, b) => sortValues(a, b, listKey, sortOrder));
  }
);

/**
 * Return a slice the trainings list, filtered by a search query.
 *
 * @param {string} searchQuery  The search query
 * @param {number} start  The start index
 * @param {number} end  The end index
 * @return {Object}  The trainings list slice
 */
export const selectSearchedSlice = createSelector(
  (state) => state.trainings,
  (_, searchQuery) => searchQuery,
  (_, __, start) => start,
  (_, __, ___, end) => end,
  (trainings, searchQuery, start, end) => {
    searchQuery = normalizeString(searchQuery).toLowerCase();
    return searchQuery.length > 2
      ? trainings.list
          .filter(
            (value) =>
              normalizeString(value.matiereNom).toLowerCase().includes(searchQuery) ||
              normalizeString(value.intitule).toLowerCase().includes(searchQuery) ||
              normalizeString(value.organisateur.nom).toLowerCase().includes(searchQuery)
          )
          .slice(start, end)
      : [];
  }
);

export default trainingsSlice.reducer;
